'use client';

const ToggleButton: FC = ({ children }) => {
  const handleToggle = () => {
    document
      .getElementById('default-layout')
      ?.classList.toggle('toggled-sidebar');
  };
  return (
    <div
      id="sidebar-icon"
      className={`top-6 right-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gohan shadow-md transition-opacity
        duration-300 ease-in-out group-hover/sidebar:opacity-100 group-[.toggled-sidebar]/layout:absolute rtl:left-0 xl:absolute xl:left-auto
        xl:-right-2 xl:opacity-0 group-[.toggled-sidebar]/layout:xl:static
        group-[.toggled-sidebar]/layout:xl:opacity-100 rtl:xl:right-auto rtl:xl:-left-2`}
      onClick={handleToggle}
    >
      {children}
    </div>
  );
};

export default ToggleButton;
