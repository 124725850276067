'use client';

import { useMemo } from 'react';
import { useRouter } from 'next/navigation';
import useCashierStore from '@/client/hooks/useCashierStore';
import updateQueryParam from '@/client/utils/updateQueryParam/updateQueryParam';
import BalanceProps from '@/server/components/NavBar/LoggedInControls/UserControls/UserBalance/Balance/BalanceProps';
import useBalance from '@/server/components/NavBar/LoggedInControls/UserControls/UserBalance/Balance/useBalance';

const Balance: FC<BalanceProps & { translationBalance: string }> = (props) => {
  const { icon, amount } = useBalance(props);
  const { setOpenStore } = useCashierStore();
  const router = useRouter();

  const handleOpen = () => {
    router.replace(updateQueryParam(location.href, 'c', 'home'), {
      scroll: false,
    });
    setOpenStore();
  };

  const [integer, fractional] = useMemo(() => amount.split('.'), [amount]);

  return (
    <div onClick={handleOpen} className="p-5 active:bg-heles">
      <div className="text-xs text-trunks pb-1">{props.translationBalance}</div>
      <div className="text-bulma font-headline">
        <span className="text-3xl font-light">{integer}.</span>
        <span className="text-base font-light">{fractional}</span>
        <span className="text-base font-light pl-1">{icon}</span>
      </div>
    </div>
  );
};

export default Balance;
