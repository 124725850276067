import type { ComponentProps } from 'react';
import {
  ControlsChevronDownSmall,
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
  ControlsChevronUpSmall,
} from '@heathmont/moon-icons-tw';

type Props = ComponentProps<typeof ControlsChevronDownSmall> & {
  arrow: 'up' | 'down' | 'left' | 'right';
};

const Chevron: FC<Props> = ({ arrow, ...props }) => {
  switch (arrow) {
    case 'up':
      return <ControlsChevronUpSmall {...props} />;
    case 'down':
      return <ControlsChevronDownSmall {...props} />;
    case 'left':
      return <ControlsChevronLeftSmall {...props} />;
    case 'right':
      return <ControlsChevronRightSmall {...props} />;
    default:
      return null;
  }
};

export default Chevron;
