'use client';

import { useRouter } from 'next/navigation';
import SettingItem from '../Settings/SettingItem';
import ControlsChevronRight from '@/external/icons/ControlsChevronRight';

type hrefProps = {
  title: JSX.Element;
  icon: JSX.Element;
  href: string;
};

type onClickProps = {
  title: JSX.Element;
  icon: JSX.Element;
  onClick: () => void;
};

const SupportItem: FC<hrefProps | onClickProps> = (props) => {
  const router = useRouter();

  const handleClick = () => {
    if ('onClick' in props) {
      return props.onClick();
    }

    return router.push(props.href);
  };

  return (
    <SettingItem
      onClick={handleClick}
      icon={props.icon}
      text={props.title}
      leadingIcon={
        <div className="w-6 h-6 text-bulma">
          <ControlsChevronRight />
        </div>
      }
    />
  );
};

export default SupportItem;
