'use client';
import Script from 'next/script';

type Props = { anjSeal?: anjSealConfig };

const EmpireLicenseScript: FC<Props> = ({ anjSeal }) => {
  const sealId = anjSeal?.id;
  const sealScript = anjSeal?.script;
  const initSeal = () => {
    const seal = (window as unknown as typeof AnjSealWindow)[`anj_${sealId}`];
    seal.init();
  };

  return <Script src={sealScript} onLoad={initSeal} strategy="lazyOnload" />;
};

export default EmpireLicenseScript;
