'use client';
import Button from '@/client/components/Button';
import useOpenCloseCashier from '@/client/components/CashierWrap/useCashier/useOpenCloseCashier';
import { CashierCurrencyType } from '@/schema';

type Props = {
  text: string;
  cashierPath?: string;
  site: Site;
  type: CashierCurrencyType;
  name: CashierCurrency;
};

const DepositButtonDesktop: FC<Props> = ({
  text,
  cashierPath,
  site,
  type,
  name,
}) => {
  const { handleOpen } = useOpenCloseCashier({ cashierPath, type, name });
  return (
    <Button
      variant="primary"
      onClick={handleOpen}
      data-testid="deposit-button"
      site={site}
    >
      {text}
    </Button>
  );
};
export default DepositButtonDesktop;
