'use client';

import SettingItem from '../SettingItem';
import languageCodeToTitleMapper from '@/client/components/BottomProfile/languageCodeToTitleMapper';
import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';
import getLanguage from '@/client/utils/getLanguage';
import ControlsChevronRight from '@/external/icons/ControlsChevronRight';
import GenericGlobe from '@/external/icons/GenericGlobe';

const LanguageItem = () => {
  const currentLanguage = getLanguage();
  const { setLanguage } = useBottomNavigationStore();

  return (
    <div onClick={() => setLanguage(true)}>
      <SettingItem
        dataTestId="bottom-profile-language-item"
        icon={<GenericGlobe className="w-6 h-6 text-bulma" />}
        text={languageCodeToTitleMapper[currentLanguage as LanguageCode]}
        leadingIcon={
          <div className="w-6 h-6 text-bulma">
            <ControlsChevronRight />
          </div>
        }
      />
    </div>
  );
};

export default LanguageItem;
