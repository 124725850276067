const fallbackMax = 2;
const absoluteMaxDigits = 20;

const sanitizeMinMaxFractionDigits = (max = fallbackMax) => {
  const sanitizedMin = 2;
  const sanitizedMax = Math.max(sanitizedMin, Math.min(absoluteMaxDigits, max));
  return {
    minimumFractionDigits: sanitizedMin,
    maximumFractionDigits: sanitizedMax,
  };
};

export default sanitizeMinMaxFractionDigits;
