import type { LinkProps } from 'next/link';
import getLanguage from '@/client/utils/getLanguage';
import getSiteInfo from '@/client/utils/getSiteInfo';
import SharedLink from '@/shared/components/Link';

type Props = Omit<LinkProps, 'locale'> & {
  className?: string;
  target?: '_blank';
};

const Link: FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const domain = global.location?.host;
  const { defaultLang, isBridgeSite } = getSiteInfo();
  return (
    <SharedLink
      {...props}
      isBridgeSite={isBridgeSite}
      language={getLanguage()}
      domain={domain}
      defaultLang={defaultLang}
    />
  );
};

export default Link;
