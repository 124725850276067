import convertBtcUnit from './helpers/convertBtcUnit';
import formatFloat from './helpers/formatFloat';
import getCurrencyDisplayIcon from './helpers/getCurrencyDisplayIcon';

const getDisplayAmount = (
  config: CashierCurrencyConfig,
  balance: number,
  currency: CashierCurrency,
  language: string,
) => {
  const icon = getCurrencyDisplayIcon(currency, config.isUbtcEnabled);
  const amount = getAmount(config, balance, currency, language);
  return { icon, amount, moneyIcon: `${amount} ${icon}` };
};

const getAmount = (
  config: CashierCurrencyConfig,
  balance: number,
  currency: CashierCurrency,
  language: string,
) => {
  if (currency === 'BTC') {
    const unitBalance = convertBtcUnit(balance, config.isUbtcEnabled);
    return formatFloat(unitBalance, language, config.maximumFractionDigits);
  }
  return formatFloat(balance, language, config.maximumFractionDigits);
};

export default getDisplayAmount;
