// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../../../schema';

import useSubscription from '@/client/hooks/useSubscription';
import gql from '@/shared/utils/gql';
export type MyBalanceChangedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type MyBalanceChangedSubscription = { __typename?: 'Subscription', bankingMyBalanceChanged?: { __typename?: 'BankingMyBalanceChangedSubscriptionPayload', clientSubscriptionId?: string, changedBalance?: { __typename?: 'BankingBalance', id: string, currency?: string, totalBalance?: number, totalBonusBalance?: number, realBalance?: number, cashierBalance?: number } } };


export const MyBalanceChangedSubscriptionDocument = gql`
    subscription MyBalanceChangedSubscription {
  bankingMyBalanceChanged(input: {}) {
    changedBalance {
      id
      currency
      totalBalance
      totalBonusBalance
      realBalance
      cashierBalance
    }
    clientSubscriptionId
  }
}
    `;

export const useMyBalanceChangedSubscription = (options: { variables?: MyBalanceChangedSubscriptionVariables, onData: (data: MyBalanceChangedSubscription) => void } ) => { 
  return useSubscription<MyBalanceChangedSubscription, MyBalanceChangedSubscriptionVariables>(MyBalanceChangedSubscriptionDocument, options);
}