'use client';
import { usePathname, useRouter } from 'next/navigation';
import CloseButton from './CloseButton';

const MenuToggle: FC = ({ children }) => {
  const pathname = usePathname();
  const isMobileSidebarOpen = pathname?.includes('/menu');
  const router = useRouter();

  const handleCloseClick = () => {
    router.back();
  };

  if (isMobileSidebarOpen) {
    return <CloseButton onClick={handleCloseClick} />;
  }
  return children;
};
export default MenuToggle;
