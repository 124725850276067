'use client';

import SupportItem from './SupportItem';
import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';
import CommsCommands from '@/client/helpers/CommsCommands';
import ChatChat from '@/external/icons/ChatChat';

type Props = {
  title: JSX.Element;
  isCommsEnabled?: boolean;
  isProtoEnabled?: boolean;
};

const SupportChat: FC<Props> = ({ title, isCommsEnabled }) => {
  const { setCloseAll } = useBottomNavigationStore();

  const handleClick = () => {
    setCloseAll();

    if (isCommsEnabled) {
      setTimeout(() => {
        CommsCommands.show({});
      }, 300);
    }
  };

  return (
    <SupportItem
      onClick={handleClick}
      icon={<ChatChat className="w-6 h-6 text-bulma" />}
      title={title}
    />
  );
};

export default SupportChat;
