'use client';
import { ReactNode } from 'react';
import useToggle from '../../../hooks/useToggle';
import classNames from '@/external/classNames';
import Chevron from '@/external/icons/Chevron';

type Props = {
  title: ReactNode;
  isBitcasino: boolean;
};

const NavItem: FC<Props> = ({ title, isBitcasino, children }) => {
  const [showList, onToggle] = useToggle();
  return (
    <div
      className={classNames(
        'relative grid h-11 content-start overflow-hidden rounded-md bg-gohan p-3 transition-[height] duration-200 ease-in-out 2xl:h-full 2xl:rounded-none 2xl:bg-transparent 2xl:p-0',
        showList && 'h-full',
      )}
    >
      <div
        className="mb-4 grid cursor-pointer grid-cols-[1fr_auto] 2xl:cursor-default 2xl:[&_svg]:hidden"
        onClick={onToggle}
      >
        <h6>{title}</h6>
        <Chevron arrow={showList ? 'up' : 'down'} />
      </div>
      <div
        className={classNames(
          '[&_a]:text-trunks [&_a]:no-underline hover:[&_a]:text-piccolo [&_nav]:grid [&_nav]:justify-start [&_nav]:gap-4',
          isBitcasino && '[&_a]:text-moon-12',
        )}
      >
        {children}
      </div>
    </div>
  );
};
export default NavItem;
