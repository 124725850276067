import floorDecimals from './floorDecimals';
import sanitizeMinMaxFractionDigits from './sanitizeMinMaxFractionDigits';

const formatFloat = (
  value: number,
  locale: string,
  maximumFractionDigits?: number,
) => {
  const result = floorDecimals(value, maximumFractionDigits);
  const sanitizedMinMax = sanitizeMinMaxFractionDigits(maximumFractionDigits);
  return new Intl.NumberFormat(locale, sanitizedMinMax).format(result);
};

export default formatFloat;
