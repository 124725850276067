/* eslint-disable immutable/no-mutation */
'use client';
import EmpireLicenseScript from '@/server/components/ExternalScripts/EmpireLicenseScript';

type Props = { anjSeal?: anjSealConfig };

const EmpireLicense: FC<Props> = ({ anjSeal }) => {
  const sealId = anjSeal?.id;

  return (
    <>
      <EmpireLicenseScript anjSeal={anjSeal} />
      <div
        id={`anj-${sealId}`}
        data-anj-seal-id={sealId}
        data-anj-image-size="40"
        data-anj-image-type="basic-small"
      />
    </>
  );
};

export default EmpireLicense;
