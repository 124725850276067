'use client';
import Carousel from '@/external/components/Carousel';
import Chevron from '@/external/icons/Chevron';

type Props = {
  slides: JSX.Element[];
  step?: number;
  selectedIndex?: number;
  className?: string;
  itemClassName?: string;
  arrowButtons?: boolean;
};

const CarouselWrap: FC<Props> = ({
  slides,
  step = 1,
  selectedIndex = 1,
  arrowButtons,
  className,
  itemClassName,
}) => (
  <Carousel step={step} selectedIndex={selectedIndex} className={className}>
    {arrowButtons && (
      <Carousel.LeftArrow className="left-px sm:-left-4">
        <Chevron arrow="left" />
      </Carousel.LeftArrow>
    )}
    <Carousel.Reel>
      {slides.map((item) => (
        <Carousel.Item
          key={item.key}
          className={`bg-transparent active:bg-heles 
            ${itemClassName}`}
        >
          {item}
        </Carousel.Item>
      ))}
    </Carousel.Reel>
    {arrowButtons && (
      <Carousel.RightArrow className="right-px sm:-right-4">
        <Chevron arrow="right" />
      </Carousel.RightArrow>
    )}
  </Carousel>
);

export default CarouselWrap;
