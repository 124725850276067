'use client';

import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';

type Props = {
  icon: JSX.Element;
  text: JSX.Element;
};

const CategoryMore: FC<Props> = ({ icon, text }) => {
  const { setMore } = useBottomNavigationStore();

  return (
    <div
      onClick={() => setMore(true)}
      className="w-16 flex flex-col items-center"
    >
      <div className="w-14 h-14 rounded-xl bg-gohan flex items-center justify-center">
        {icon}
      </div>
      <div className="mt-3 text-trunks text-xs text-center">{text}</div>
    </div>
  );
};

export default CategoryMore;
