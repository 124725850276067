'use client';

import { useRouter } from 'next/navigation';
import SettingItem from '../SettingItem';
import ControlsChevronRight from '@/external/icons/ControlsChevronRight';
import NotificationsBell from '@/external/icons/NotificationsBell';

type Props = {
  text: React.ReactNode;
  unread: number | undefined;
};

const NotificationItem: FC<Props> = ({ text, unread }) => {
  const router = useRouter();

  return (
    <div onClick={() => router.replace('/profile/notifications')}>
      <SettingItem
        icon={<NotificationsBell className="w-6 h-6 text-bulma" />}
        text={text}
        leadingIcon={
          <div className="relative flex items-center">
            {!!unread && (
              <div className="w-5 h-5 flex justify-center items-center text-white bg-piccolo text-xs rounded-full mr-4">
                {unread}
              </div>
            )}
            <div className="w-6 h-6 text-bulma">
              <ControlsChevronRight />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default NotificationItem;
