'use client';

import SettingItem from '../SettingItem';
import useLogout from '@/client/components/LogoutButton/hooks/useLogout';
import GenericLogOut from '@/external/icons/GenericLogOut';

type Props = {
  text: React.ReactNode;
  site: Site;
  siteConfig: SiteConfig;
  cognitoConfig?: CognitoConfigFragment;
};

const LogOutItem: FC<Props> = ({ text, site, siteConfig, cognitoConfig }) => {
  const { logout } = useLogout(siteConfig, site, cognitoConfig);

  return (
    <SettingItem
      onClick={logout}
      icon={<GenericLogOut className="w-6 h-6 text-bulma" />}
      text={text}
    />
  );
};

export default LogOutItem;
