import getTimeLeftInMillisUntil from '@/shared/utils/date/getTimeLeftInMillisUntil';
import minutesToMilliseconds from '@/shared/utils/date/minutesToMilliseconds';
import secondsToMilliseconds from '@/shared/utils/date/secondsToMilliseconds';

type Props = Pick<
  TimerOptions,
  'zeroed' | 'endDate' | 'minutesLeft' | 'secondsLeft'
>;

const getTimeLeftInMillis = ({
  zeroed,
  secondsLeft,
  minutesLeft,
  endDate,
}: Props) => {
  if (zeroed) {
    return 0;
  }
  if (secondsLeft) {
    return secondsToMilliseconds(secondsLeft);
  }
  if (minutesLeft) {
    return minutesToMilliseconds(minutesLeft);
  }
  return getTimeLeftInMillisUntil(endDate);
};

export default getTimeLeftInMillis;
