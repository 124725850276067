'use client';
import BalanceProps from './BalanceProps';
import useBalance from './useBalance';
import classNames from '@/external/classNames';
import InlineContainer from '@/shared/components/InlineContainer';

const Balance: FC<BalanceProps> = (props) => {
  const { icon, amount } = useBalance(props);
  return (
    <InlineContainer className={classNames('gap-1', props.className)}>
      <p className="text-moon-12 text-bulma" data-testid="balance-amount">
        {amount}
      </p>
      <p className="text-moon-12 text-trunks" data-testid="balance-currency">
        {icon}
      </p>
    </InlineContainer>
  );
};

export default Balance;
