'use client';
import useOpenCloseCashier from '@/client/components/CashierWrap/useCashier/useOpenCloseCashier';
import { CashierCurrencyType } from '@/schema';

type Props = {
  isMobile: boolean;
  type: CashierCurrencyType;
  name: CashierCurrency;
};

const CashierLink: FC<Props> = ({ children, type, name }) => {
  const { handleOpen } = useOpenCloseCashier({ type, name });
  return <div onClick={handleOpen}>{children}</div>;
};

export default CashierLink;
