import { useEffect } from 'react';

type Args = { onChange: () => void };

const useCurrencyChangeListener = ({ onChange }: Args) => {
  useEffect(() => {
    global.addEventListener('currencySwitched', onChange);
    return () => global.removeEventListener('currencySwitched', onChange);
  });
};

export default useCurrencyChangeListener;
