'use client';

import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

type Props = {
  handle: string;
};

const WrapperLink: FC<Props> = ({ children, handle }) => {
  const sendEvent = () => {
    sendEventToUmami(
      {
        menu: handle,
      },
      'desktopSideMenuClick',
    );
  };

  return <div onClick={sendEvent}>{children}</div>;
};

export default WrapperLink;
