'use client';

import { useEffect, useMemo, useState } from 'react';
import useBannerCarousel from './hooks/useBannerCarousel';
import Slide from './Slide';
import Stepper from './Stepper';
import getTournamentsSlider from '@/actions/getTournamentsSlider';
import CarouselWrap from '@/client/components/CarouselWrap';
import SessionStorage from '@/client/utils/storage/SessionStorage';
import ControlsClose from '@/external/icons/ControlsClose';

type Slide = {
  id: string;
  title: string;
  smallImage: string;
  summary: string;
  closeAt: string;
  shortUrl: string;
}[];

const DELAY = 5000;

type Props = {
  isLoggedIn: boolean;
  site: Site;
};

const Slider: FC<Props> = ({ isLoggedIn, site }) => {
  const closedNotificationIds = Boolean(
    SessionStorage.getItem('closed_bottom_banner', true) as string,
  );

  const [slides, setSlides] = useState<Slide>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShown, setIsShown] = useState(closedNotificationIds);

  const active = useBannerCarousel({
    totalStep: slides.length,
    delay: DELAY,
  });

  const onCloseBanner = () => {
    setIsShown(false);

    SessionStorage.setItem('closed_bottom_banner', false);
  };

  useEffect(() => {
    getTournamentsSlider()
      .then((data) => {
        setIsLoading(false);
        setSlides(data);
      })
      .catch((error) => {
        console.error('Error fetching slider:', error);
      });
  }, [site]);

  const slideComponents = useMemo(
    () => slides.map((slide) => <Slide key={slide.id} {...slide} />),
    [slides],
  );

  if (!isShown) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="p-2 mt-1 border-beerus border rounded-xl">
        <div className="flex w-full rounded-moon-s-xs bg-gradient-to-r from-goku to-gohan h-16" />
      </div>
    );
  }

  if (slides.length === 0) {
    return null;
  }

  return (
    <div className="relative mx-1">
      <CarouselWrap
        slides={slideComponents}
        step={active}
        selectedIndex={active}
        className="p-2 mt-1 border-beerus border rounded-xl pr-8"
        itemClassName="w-full"
      />
      {isLoggedIn && (
        <div className="top-2 right-2 absolute">
          <ControlsClose
            className="text-moon-20 text-bulma"
            onClick={onCloseBanner}
          />
        </div>
      )}
      <Stepper steps={slides.length} active={active} />
    </div>
  );
};

export default Slider;
