'use client';
import React, { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import useBottomNavigationStore from './useBottomNavigationStore';
import MenuToggle from '../BottomNavigation/MenuToggle';
import Link from '../Link';
import usePathHistory from '@/client/hooks/router/usePathHistory';
import BottomSheet from '@/external/components/BottomSheet';
import GenericMenu from '@/external/icons/GenericMenu';

type Props = {
  mainPanel: React.ReactNode;
  languagePanel: React.ReactNode;
  morePanel: React.ReactNode;
  loggedOutPanel: React.ReactNode;
  isLoggedIn: boolean;
  isNewBottomMenu: boolean;
};

const BottomProfile: FC<Props> = ({
  mainPanel,
  languagePanel,
  morePanel,
  loggedOutPanel,
  isLoggedIn,
  isNewBottomMenu,
}) => {
  const pathname = usePathname();
  const {
    isMainOpened,
    setMain,
    isLanguageOpened,
    setLanguage,
    isMoreOpened,
    setMore,
    isLoggedOutOpened,
    setLoggedOut,
    setCloseAll,
  } = useBottomNavigationStore();
  const ref = useRef<HTMLDivElement>(null);
  const { savePreviousPath } = usePathHistory();

  useEffect(() => {
    setCloseAll();
  }, [pathname, setCloseAll]);

  useEffect(() => {
    setCloseAll();
  }, [isLoggedIn, setCloseAll]);

  const onCloseMain = isMainOpened
    ? () => setMain(false)
    : () => setLoggedOut(false);

  if (!isNewBottomMenu) {
    return (
      <div
        className="w-full h-full flex justify-center items-center text-moon-32 text-bulma"
        data-testid="bottom-profile-open"
      >
        <MenuToggle>
          <Link
            href="/menu"
            onClick={savePreviousPath}
            className="grid h-12 place-items-center gap-4"
          >
            <GenericMenu className="text-moon-32 text-bulma" />
          </Link>
        </MenuToggle>
      </div>
    );
  }

  return (
    <>
      <div
        className="w-full h-full flex justify-center items-center text-moon-32 text-bulma"
        data-testid="bottom-profile-open"
        onClick={() => (isLoggedIn ? setMain(true) : setLoggedOut(true))}
      >
        <div className="grid h-12 place-items-center gap-4">
          <GenericMenu className="text-moon-32 text-bulma" />
        </div>
      </div>
      <div ref={ref} />

      <BottomSheet
        rootId="default-layout"
        open={isMainOpened || isLoggedOutOpened}
        initialFocus={ref}
      >
        <BottomSheet.Panel
          className="h-[95%] p-0 pt-2 bg-goku"
          onClose={onCloseMain}
        >
          <div data-testid="navigation-panel">
            <BottomSheet.Draghandle />
            {isMainOpened && mainPanel}
            {isLoggedOutOpened && loggedOutPanel}
          </div>
        </BottomSheet.Panel>
        <BottomSheet.Backdrop onClose={onCloseMain} />
        <BottomSheet
          rootId="default-layout"
          open={isMoreOpened}
          initialFocus={ref}
        >
          <BottomSheet.Panel
            className="h-[95%] p-0 pt-2 bg-goku"
            onClose={() => setMore(false)}
          >
            <BottomSheet.Draghandle />
            {morePanel}
          </BottomSheet.Panel>
          <BottomSheet.Backdrop onClose={() => setMore(false)} />
        </BottomSheet>
        <BottomSheet
          rootId="default-layout"
          open={isLanguageOpened}
          initialFocus={ref}
        >
          <BottomSheet.Panel
            className="h-[95%] p-0 pt-2 bg-goku"
            onClose={() => setLanguage(false)}
          >
            <BottomSheet.Draghandle />
            {languagePanel}
          </BottomSheet.Panel>
          <BottomSheet.Backdrop onClose={() => setLanguage(false)} />
        </BottomSheet>
      </BottomSheet>
    </>
  );
};

export default BottomProfile;
