import classNames from '@/external/classNames';

type Props = {
  steps: number;
  active: number;
};

const Stepper = ({ steps, active }: Props) => (
  <div className="flex absolute right-2 bottom-3 gap-[1px]">
    {new Array(steps).fill(0).map((_, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={classNames(
          'bg-beerus rounded-lg w-5 h-[2px]',
          index === active && 'bg-bulma',
        )}
      />
    ))}
  </div>
);

export default Stepper;
