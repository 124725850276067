import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const ControlsCloseSmall = () => (
  <Img
    src={getCdnMoonIcon('icons/ControlsCloseSmall.png')}
    dpr={2}
    alt=""
    width={32}
    height={32}
  />
);
export default ControlsCloseSmall;
