const languageCodeToTitleMapper: Record<LanguageCode, string> = {
  ar: 'العربية',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fi: 'Suomi',
  fr: 'Français',
  ja: '日本語',
  ko: '한국어',
  no: 'Norsk',
  pt: 'Português',
  th: 'ไทย',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  zh: '中文',
};

export default languageCodeToTitleMapper;
