'use client';
import { usePathname, useRouter } from 'next/navigation';
import CloseButton from '../BottomNavigation/CloseButton';

const SearchButton: FC = ({ children }) => {
  const pathname = usePathname();
  const router = useRouter();
  const handleClick = () => {
    // TODO: use previous path and redirect to '/' if no way back
    router.back();
  };

  if (pathname?.includes('/search')) {
    return <CloseButton onClick={handleClick} />;
  }
  return children;
};

export default SearchButton;
