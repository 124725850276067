'use client';

import { useRouter } from 'next/navigation';
import CloseButton from '@/client/components/BottomNavigation/CloseButton';
import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';
import IconButton from '@/external/components/IconButton';
import GenericSettings from '@/external/icons/GenericSettings';

const Controls = () => {
  const router = useRouter();
  const { setMain } = useBottomNavigationStore();

  return (
    <div className="flex gap-2">
      <IconButton
        data-testid="bottom-profile-settings"
        onClick={() => router.replace('/profile/settings')}
        className="bg-transparent p-0 w-10 h-10"
      >
        <GenericSettings className="text-moon-32 text-bulma" />
      </IconButton>
      <CloseButton
        dataTestId="bottom-profile-close"
        onClick={() => setMain(false)}
      />
    </div>
  );
};

export default Controls;
