'use client';

import { notFound, useParams } from 'next/navigation';

type Props = {
  languages: LanguageCode[];
};

const LanguageGuard: FC<Props> = ({ children, languages }) => {
  const params = useParams();
  const lang = params?.lang;

  if (!!lang && !languages.includes(lang as LanguageCode)) {
    return notFound();
  }

  return children;
};

export default LanguageGuard;
