import getEventData from './getEventData';
import GameType from './types/GameType';
import SessionStorage from '@/client/utils/storage/SessionStorage';

const saveEventData = (data: GameType) => {
  const prevVal = getEventData(data.event);

  if (prevVal === null) {
    return SessionStorage.setItem(data.event, data);
  }

  const updatedVal = {
    ...prevVal,
    ...data,
  };

  return SessionStorage.setItem(data.event, updatedVal);
};

export default saveEventData;
