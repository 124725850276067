'use client';
import Button from '@/client/components/Button';
import useOpenCloseCashier from '@/client/components/CashierWrap/useCashier/useOpenCloseCashier';
import ControlsPlus from '@/external/icons/ControlsPlus';
import { CashierCurrencyType } from '@/schema';

type Props = {
  text: string;
  cashierPath?: string;
  site: Site;
  type: CashierCurrencyType;
  name: CashierCurrency;
  totalBalance?: number;
};

const DepositButtonBottomMenu: FC<Props> = ({
  text,
  cashierPath,
  site,
  type,
  name,
  totalBalance,
}) => {
  const { handleOpen } = useOpenCloseCashier({ cashierPath, type, name });
  if (!totalBalance) {
    return (
      <Button
        site={site}
        className="m-1 h-8 px-2.5 py-0"
        variant="primary"
        onClick={handleOpen}
      >
        {text}
        <ControlsPlus className="text-moon-22" />
      </Button>
    );
  }
  return (
    <Button
      site={site}
      className="h-8 w-8 rounded-full border-none bg-gohan px-0 py-1 shadow-moon-sm"
      aria-label={text}
      onClick={handleOpen}
    >
      <ControlsPlus className="text-moon-24 text-piccolo" />
    </Button>
  );
};

export default DepositButtonBottomMenu;
