'use client';

import { useState } from 'react';
import languageCodeToTitleMapper from '../languageCodeToTitleMapper';
import useLanguageSwitcher from '@/client/components/Footer/LanguageSwitcher/useLanguageSwitcher';
import Button from '@/external/components/Button';
import Radio from '@/external/components/Radio';

type Props = {
  languages: LanguageCode[];
  currentLanguage: string;
  languageFeatureFlags: Partial<Record<LanguageCode, boolean>>;
  defaultLang: string;
  isBridgeSite: boolean;
  site: Site;
  isLoggedIn: boolean;
  saveText: string;
};

const ToggleList: FC<Props> = ({
  languages,
  currentLanguage,
  languageFeatureFlags,
  defaultLang,
  isBridgeSite,
  site,
  isLoggedIn,
  saveText,
}) => {
  const [language, setLanguage] = useState(currentLanguage);
  const [isLoading, setIsLoading] = useState(false);

  const { handleChange } = useLanguageSwitcher({
    currentLanguage,
    isLoggedIn,
    defaultLang,
    isBridgeSite,
    site,
    languages,
    languageFeatureFlags,
  });

  const onChange = (lang: string) => {
    setLanguage(lang);
  };

  return (
    <div className="pb-20">
      <Radio value={language} onChange={onChange} className="p-5">
        {languages.map((lang: LanguageCode) => (
          <Radio.Option
            key={lang}
            value={lang}
            className="w-full p-2 justify-between text-base mb-2 items-center"
          >
            {languageCodeToTitleMapper[lang]}
            <Radio.Indicator />
          </Radio.Option>
        ))}
      </Radio>
      <div className="py-6 px-5 fixed bottom-0 left-0 bg-goku w-full border-t border-beerus">
        <Button
          onClick={() => {
            setIsLoading(true);
            handleChange(language);
          }}
          variant="primary"
          size="lg"
          fullWidth
          animation={isLoading ? 'progress' : undefined}
        >
          {saveText}
        </Button>
      </div>
    </div>
  );
};

export default ToggleList;
