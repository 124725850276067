const SessionStorage = (() => ({
  getItem: <T>(name: string, defaultValue?: T) => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    return sessionStorage.getItem(name)
      ? JSON.parse(sessionStorage.getItem(name) ?? '')
      : defaultValue;
  },
  setItem: (name: string, value: unknown) => {
    sessionStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    sessionStorage.removeItem(name);
  },
}))();

export default SessionStorage;
