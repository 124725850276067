import Cookies from 'js-cookie';
import getSiteInfo from '@/client/utils/getSiteInfo';
import LANGUAGE_COOKIE from '@/shared/utils/cookie/LANGUAGE_COOKIE';

const getLanguage = () => {
  const language = Cookies.get(LANGUAGE_COOKIE);
  const { defaultLang } = getSiteInfo();
  return language ?? defaultLang;
};

export default getLanguage;
