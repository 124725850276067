'use client';

import { useState } from 'react';
import dynamic from 'next/dynamic';
import InfoBox from './InfoBox';
import { useMyLoyaltyStatusSubscription } from '@/client/components/Loyalty/graphql/MyLoyaltyStatusSubscription.generated';
import Img from '@/shared/components/Img';

const ChartEx = dynamic(() => import('@/external/components/ChartEx'));

type Props = {
  initialTierPoints: number | undefined;
  initialPoints: string | undefined;
  currentTierURL: string | undefined;
  currentTierName: string | undefined;
  levelText: string;
  levelNextText: string;
  currentTheme: ColorScheme;
  handleOpen: () => void;
};

const Info: FC<Props> = ({
  initialTierPoints,
  initialPoints,
  currentTierName,
  currentTierURL,
  levelText,
  levelNextText,
  currentTheme,
  handleOpen,
}) => {
  const [tierPoints, setTierPoints] = useState(initialTierPoints);
  const [points, setPoints] = useState(initialPoints);

  useMyLoyaltyStatusSubscription({
    variables: {
      input: {},
    },
    onData: (data) => {
      const { myStatus } = data.loyaltyPublicMyStatusUpdated || {};
      if (myStatus?.nextMilestone?.points) {
        setPoints(myStatus.nextMilestone.points);
      }
      if (myStatus?.tierPoints) {
        setTierPoints(Math.trunc(myStatus.tierPoints));
      }
    },
  });

  return (
    <div className="flex gap-1">
      {currentTierName && currentTierURL && (
        <InfoBox
          value={currentTierName}
          description={levelText}
          visualWidget={
            <Img src={currentTierURL} width={112} height={112} alt="status" />
          }
          handleClick={handleOpen}
        />
      )}
      {tierPoints !== undefined && points !== undefined && (
        <InfoBox
          value={String(Number(points) - tierPoints)}
          description={levelNextText}
          visualWidget={
            <ChartEx
              currentVal={tierPoints}
              maxVal={Number(points)}
              currentTheme={currentTheme}
            />
          }
          handleClick={handleOpen}
        />
      )}
    </div>
  );
};

export default Info;
