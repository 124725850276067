'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import ControlsCloseSmall from '@/external/icons/ControlsCloseSmall';
import getCdnImg from '@/shared/cdn/getCdnImg';
import Img from '@/shared/components/Img';
import getThemeClassName from '@/shared/utils/getThemeClassName';

type Props = {
  text: JSX.Element;
  site: Site;
};

const InviteBanner: FC<Props> = ({ text, site }) => {
  const router = useRouter();
  const [isClosed, setIsClosed] = useState(
    localStorage.getItem('inviteBannerClosed') === 'true',
  );

  const lightTheme = getThemeClassName(site, 'light');

  const onClick = () => {
    localStorage.setItem('inviteBannerClosed', 'true');

    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  const handleClick = () => {
    router.replace('/profile/refer-a-friend');
  };

  return (
    <div className="my-1">
      <div
        onClick={handleClick}
        className={`flex w-full items-center p-2 border-beerus border rounded-xl pr-28 relative bg-goku ${lightTheme}`}
      >
        <div
          onClick={onClick}
          className="text-moon-24 text-bulma top-2 right-2 absolute"
        >
          <ControlsCloseSmall />
        </div>
        <div className="w-20 h-16 rounded-xl overflow-hidden shrink-0">
          <Img
            className="h-full object-cover"
            width={87}
            height={66}
            src={getCdnImg('refer-a-friend/speaker.svg')}
            alt="banner"
          />
        </div>
        <div className="ml-3">
          <div className="text-bulma text-base font-headline">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default InviteBanner;
