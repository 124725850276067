type Props = {
  icon: React.ReactNode;
  text: React.ReactNode;
  leadingIcon?: React.ReactNode;
  onClick?: () => void;
  dataTestId?: string;
};

const SettingItem: FC<Props> = ({
  icon,
  text,
  leadingIcon,
  onClick,
  dataTestId,
}) => (
  <div
    onClick={onClick}
    className="px-1 py-2 flex justify-between items-center active:bg-heles"
    data-testid={dataTestId}
  >
    <div className="flex items-center">
      {icon}
      <span className="ml-3 text-sm text-bulma">{text}</span>
    </div>
    {leadingIcon}
  </div>
);

export default SettingItem;
