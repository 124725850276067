import { useEffect } from 'react';

type Args = { onChange: () => void };

const useCashierCurrencyChange = ({ onChange }: Args) => {
  useEffect(() => {
    const handleCashierEvent = (event: MessageEvent) => {
      const isCashierEvent = event.data?.source === 'cashier';
      if (isCashierEvent) {
        onChange();
      }
    };
    global.addEventListener('message', handleCashierEvent);
    return () => global.removeEventListener('message', handleCashierEvent);
  });
};

export default useCashierCurrencyChange;
