'use client';

import CloseButton from '../../BottomNavigation/CloseButton';
import useBottomNavigationStore from '../useBottomNavigationStore';

const CloseButtonWrap = () => {
  const { setCloseAll } = useBottomNavigationStore();

  return <CloseButton onClick={setCloseAll} />;
};

export default CloseButtonWrap;
