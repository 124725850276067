'use client';

import CloseButtonWrap from './CloseButtonWrap';
import useBottomNavigationStore from '../useBottomNavigationStore';
import IconButton from '@/external/components/IconButton';
import ArrowsLeft from '@/external/icons/ArrowsLeft';

type Props = {
  backSource?: 'language' | 'more';
  title: string;
};
const Header: FC<Props> = ({ backSource, title }) => {
  const { setLanguage, setMore } = useBottomNavigationStore();

  const onBack = () => {
    if (backSource === 'language') {
      setLanguage(false);
    }

    if (backSource === 'more') {
      setMore(false);
    }
  };

  return (
    <div className="p-5 flex justify-between border-b-beerus border-b items-center">
      {backSource && (
        <IconButton
          data-testid="bottom-menu-back-button"
          onClick={onBack}
          className="bg-transparent p-0 w-10 h-10 text-bulma"
        >
          <ArrowsLeft />
        </IconButton>
      )}
      <div className="text-base flex-grow text-center text-bulma">{title}</div>
      <CloseButtonWrap />
    </div>
  );
};

export default Header;
