import Cookies from 'js-cookie';

type SiteInfo = { site: string; isBridgeSite: boolean; defaultLang: string };

const getSiteInfo = () => {
  const siteInfoCookie = Cookies.get('siteInfo') ?? '{}';
  return JSON.parse(siteInfoCookie) as SiteInfo;
};

export default getSiteInfo;
