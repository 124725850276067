import getTimeLeft from './getTimeLeft';
import useMillisecondsRemainingInterval from './useMillisecondsRemainingInterval';

type TimerOptions = {
  endDate?: string;
  format?: TimerFormat;
};

const useTimer = (options: TimerOptions) => {
  const timeLeftInMillis = useMillisecondsRemainingInterval({
    endDate: options.endDate,
  });

  return getTimeLeft(options.format, timeLeftInMillis);
};

export default useTimer;
