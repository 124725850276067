type Props = {
  visualWidget: JSX.Element;
  description: string;
  value: string;
  handleClick: () => void;
};

const InfoBox: FC<Props> = ({
  visualWidget,
  description,
  value,
  handleClick,
}) => (
  <div
    onClick={handleClick}
    className="px-5 pt-6 pb-4 bg-gohan h-52 flex-grow rounded-xl flex flex-col items-center active:bg-heles"
  >
    <div className="w-28 h-28">{visualWidget}</div>
    <div className="text-xs text-trunks mt-1">{description}</div>
    <div className="text-2xl text-bulma font-headline font-light">{value}</div>
  </div>
);

export default InfoBox;
