import { useMemo } from 'react';
import { useRouter } from 'next/navigation';
import useTimer from '@/client/hooks/useTimer';
import Img from '@/shared/components/Img';
import getImgSource from '@/shared/utils/getImgSource';

type Props = {
  id: string;
  title: string;
  smallImage: string;
  summary: string;
  closeAt: string;
  shortUrl: string;
};

const Slide: FC<Props> = ({
  title,
  smallImage,
  summary,
  closeAt,
  shortUrl,
}) => {
  const router = useRouter();
  const timeLeft = useTimer({
    endDate: closeAt,
  });

  const handleOpen = () => {
    router.push(`/tournaments/${shortUrl}`);
  };

  const image = useMemo(() => getImgSource(smallImage), [smallImage]);

  return (
    <div onClick={handleOpen} className="flex w-full">
      <div className="w-20 h-16 rounded-xl overflow-hidden shrink-0">
        <Img
          className="h-full object-cover"
          width={280}
          height={264}
          src={image}
          alt={title}
        />
      </div>
      <div className="ml-3">
        <div className="text-bulma text-base font-headline line-clamp-1">
          {title}
        </div>
        <div className="text-bulma text-sm font-normal mb-1 line-clamp-1">
          {summary}
        </div>
        <div className="text-xs text-trunks">
          {timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s
        </div>
      </div>
    </div>
  );
};

export default Slide;
