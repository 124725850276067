'use client';

import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';
import saveEventData from '@/client/helpers/trackEvent/umamiEvents/saveEventData';

type Props = {
  handle: string;
};

const AsideLinkWrap: FC<Props> = ({ handle, children }) => (
  <div
    onClick={() => {
      sendEventToUmami(
        {
          menu: handle,
        },
        'desktopSideMenuClick',
      );

      if (handle === 'virtual-sports') {
        saveEventData({
          event: 'launchGame',
          page: 'aside_menu',
        });
      }
    }}
  >
    {children}
  </div>
);

export default AsideLinkWrap;
