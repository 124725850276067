import { useEffect, useState } from 'react';
import getTimeLeftInMillis from './getTimeLeftInMillis';
import useInterval from '@/client/hooks/useInterval';

const ONE_SECOND = 1000;

const getTimestamp = () => Math.round(new Date().getTime());

const useMillisecondsRemainingInterval = (options: TimerOptions) => {
  const { secondsLeft, minutesLeft, endDate } = options;
  const [value, setValue] = useState<number>();

  useEffect(
    () =>
      setValue(
        getTimeLeftInMillis({
          secondsLeft,
          minutesLeft,
          endDate,
        }),
      ),
    [secondsLeft, minutesLeft, endDate],
  );

  useInterval(
    () => {
      if (options.endDate) {
        const millisecondsRemaining = getMillisecondsRemaining(options.endDate);
        setValue(() => millisecondsRemaining);
      } else {
        setValue((previous) => calculateTimeLeft(options, previous));
      }
    },
    ONE_SECOND,
    [options],
  );

  return value;
};

const calculateTimeLeft = (options: TimerOptions, previous = 0) => {
  if (options.stuck && previous) {
    return previous;
  }
  if (previous <= ONE_SECOND) {
    return 0;
  }
  return previous - ONE_SECOND;
};

const getMillisecondsRemaining = (endDate: string) => {
  const endTimestamp = new Date(endDate);
  const formatedEndTimestamp = Math.round(endTimestamp.getTime());
  const currentTimestamp = getTimestamp();
  const remaining = formatedEndTimestamp - currentTimestamp;
  return Math.max(remaining, 0);
};

export default useMillisecondsRemainingInterval;
