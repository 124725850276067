'use client';

import { useRouter } from 'next/navigation';
import useCashierStore from '@/client/hooks/useCashierStore';
import updateQueryParam from '@/client/utils/updateQueryParam/updateQueryParam';

type Props = {
  title: JSX.Element;
  icon: JSX.Element;
  page?: string;
};

const ActionButton: FC<Props> = ({ title, icon, page }) => {
  const router = useRouter();
  const { setOpenStore } = useCashierStore();

  const handleOpen = () => {
    router.replace(updateQueryParam(location.href, 'c', page), {
      scroll: false,
    });
    setOpenStore();
  };

  return (
    <div
      onClick={handleOpen}
      tabIndex={0}
      className="py-3 border-beerus border flex flex-col rounded-xl flex-grow h-28 justify-between items-center flex-1 active:bg-heles"
    >
      <div className="p-3">{icon}</div>
      <div className="text-xs font-normal text-bulma text-center px-2">
        {title}
      </div>
    </div>
  );
};

export default ActionButton;
