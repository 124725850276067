'use client';

import { useRouter } from 'next/navigation';
import Info from '../Info';

type Props = {
  initialTierPoints: number | undefined;
  initialPoints: string | undefined;
  currentTierURL: string | undefined;
  currentTierName: string | undefined;
  levelText: string;
  levelNextText: string;
  currentTheme: ColorScheme;
};

const InfoWrapper: FC<Props> = ({
  initialTierPoints,
  initialPoints,
  currentTierName,
  currentTierURL,
  levelText,
  levelNextText,
  currentTheme,
}) => {
  const router = useRouter();

  const handleOpen = () => {
    if (initialTierPoints) {
      router.push(`/profile/loyalty`);
    } else {
      router.push(`/loyalty`);
    }
  };

  return (
    <Info
      initialTierPoints={initialTierPoints}
      initialPoints={initialPoints}
      currentTierURL={currentTierURL}
      currentTierName={currentTierName}
      levelText={levelText}
      levelNextText={levelNextText}
      currentTheme={currentTheme}
      handleOpen={handleOpen}
    />
  );
};

export default InfoWrapper;
