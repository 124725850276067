'use client';
import IconButton from '@/external/components/IconButton';
import ControlsClose from '@/external/icons/ControlsClose';

type Props = { onClick: () => void; dataTestId?: string };

const CloseButton: FC<Props> = ({ onClick, dataTestId }) => (
  <IconButton
    data-testid={dataTestId}
    onClick={onClick}
    className="bg-transparent p-0 w-10 h-10"
  >
    <ControlsClose className="text-moon-24 text-bulma" />
  </IconButton>
);

export default CloseButton;
